import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function Now() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">Now</h1>
                <p className="title-grayed text-center">This is a now page. It's about what I'm doing at this point in my life.</p>
                <h3 className="bold-font">🎯 Main focus</h3>
                <p className="text-now-page">Constantly focusing on developing <a className="now-link" href="https://joinstack.tech">Joinstack</a>. Writing my first book, Worth it, that will be available next year.</p>
                <h3 className="bold-font">👨‍🎓 Learning</h3>
                <p className="text-now-page">I'm currently learning for the Google Cybersecurity Professional Certificate.</p>
                <h3 className="bold-font">📖 Reading</h3>
                <p className="text-now-page">- Bounce by Matthew Syed</p>
                <p className="text-now-page">- The 12 week year by Brian Moran</p>
                <p className="text-now-page"><a className="now-link" href="/books-read">Here</a> you can find a complete list of all the books I've read in 2022.</p>
                <h3 className="bold-font">📪 Contact me</h3>
                <p className="text-now-page"> You can contact me by email at <a className="now-link" href = "mailto: david@joinstack.tech">david@joinstack.tech</a> to say hi! I always appreciate meeting new people.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Now;
