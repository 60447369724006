import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';

import mainImage from '../../images/march-picture.jpeg';

function March2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">March 2022</h1>

                <p className="title-grayed text-center"> Close to burnout, but life is good...</p>

                <img src={mainImage} className="img-responsive center-block img-posts"></img>

                <p className="text-now-page"> March was hard. Worked more than I want to admit, stayed at my desk
                a lot trying to make progress on my goals and read some great books. I'm a little bit burned out due to my style of working: hustling without breaks and sleeping short chunks of time.
                </p>
                <p className="text-now-page">One thing that I'm doing right now, is trying to find ways to consolidate my position in the market for 
                Joinstack. And one way of doing this is to address the market for startups that want to develop their product very fast, with an MVP (Minimum Viable Product).
                Will tackle this, through a new product, <a className="now-link" href="https://mvpmonth.com">MVP Month</a>, which will be part of Joinstack. 
                A consultancy startup that will focus on providing an MVP in under a month for our clients. From wireframes to functional apps. 
                </p>

                <p className="text-now-page"> In March my reading pile included: We need to talk about Putin, The almanack of Naval Ravikant, 
                The Twins of Auschwitz and The unfair advantage.</p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> I still need to resolve the problem that I have with sleep or the lack of it. I'm close to burn out
                and I don't feel well. Mostly because I still sleep from 4-5 AM to 8 AM all week.
                Weight loss is another thing I want to focus on in the long term. Last month I had a break from my very strict diet. 
                This month is all about weight loss (I'm targeting 6-8 kg). 
                Finally, I want to work on my procrastination. Even though I do a lot
                of things every day, I feel that I can have more results if I focus more and get shit done.</p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> I will focus mainly on the Joinstack projects that we currently have in progress. 
                I also want to continue the weight loss process (my goal is 8 kg's this month), create a website for <a className="now-link" href="https://mvpmonth.com">MVP Month</a>,
                launch a new product for developers, <a className="now-link" href="https://coderpaths.com">Coder Paths</a>, 
                launching the first version of an app for time control (DoFocus) 
                on Android and iOS, continue investing in stock and crypto, creating my first collection of NFT's, write 4 articles on this website, 
                finish some tasks for 2 crypto projects, launching an npm package, and finally launching two free themes 
                (Lansera and Agency) for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

                <div className="row cards-stats-books">
                    <h3 className="text-center">Month stats</h3>
                     <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">4</p>
                        <p className="text-center text-numbers">Books</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">264</p>
                        <p className="text-center text-numbers">Worked hours</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">34</p>
                        <p className="text-center text-numbers">Meetings</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">- 2 kg</p>
                            <p className="text-center text-numbers">Weight loss</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">120</p>
                            <p className="text-center text-numbers">Commits</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">3.2 %</p>
                            <p className="text-center text-numbers">Investment return</p>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}



export default March2022;

