import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';



function January2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">January 2022</h1>

                <p className="title-grayed text-center"> Planning, water fasting and finding myself</p>

                <p className="text-now-page"> January was a crazy month. It was surely full of challenges but also awesome outcomes. Mainly because was packed with a ton of work for my business. I've worked for 280 hours. Also this month I've tried to lose some weight doing water fasting, and I've lost a total of 8kg. I've read a lot, wrote on my book that is due to be launched this year, did a lot of planning, had endless meetings but I've also had an extended weekend to catch-up with my sanity. </p>

                <p className="text-now-page"> In January my reading pile included: 12 rules of life, Infinite game, Models, No more Mr. Nice Guy, Shoe Dog and a tech book.</p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> Firstly I want to change my sleep schedule. I’ve slept for multiple days less than 4 hours. Another thing will be exercising. I feel like I don’t get so much phisical activity these days and I want to be able to run again 10km in a session. Finally I want to take my time and relax more. </p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> 5 days of water fasting, improvements to the <a className="now-link" href="https://joinstack.tech">joinstack</a> website, two new chapters for my book: Crushing Idols, launching first version of an app for water fasting on Android and hopefully iOS, finishing planning for an upcoming trip to Stockholm and Oslo, writing 4 articles on this website, creating a decentralized cross-layer-2-bridge for a client and finally launching two free themes for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

            </div>

            <Footer />

        </div>

    )

}



export default January2022;
