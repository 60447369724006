import React from 'react';


function Footer() {
    return (
        <div className="new-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Contact me</h2>
                        <p className="footer-email text-center">david@joinstack.tech</p>
                        <div className="social-icons text-center">
                            <ul>
                                <li>
                                    <a href="https://github.com/davidbanu" aria-label="Github">
                                        <i className="fa fa-github fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/davidbanu_" aria-label="Instagram">
                                        <i className="fa fa-instagram fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/@davidbanu" aria-label="Medium">
                                        <i className="fa fa-medium fa-lg" aria-hidden />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Read out more</h2>
                        <div className="footer-company-list text-center">
                            <ul>
                                <li>
                                    <a href="/about" className="footer-company-items">About me</a>
                                </li>
                                <li>
                                    <a href="/blog" className="footer-company-items">Blog</a>
                                </li>
                                <li>
                                    <a href="/now" className="footer-company-items">Now</a>
                                </li>
                                <li>
                                    <a href="/month-notes" className="footer-company-items">Month Notes</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                    <h2 className="footer-title text-center">Follow me on</h2>
                        <div className="footer-company-list text-center">
                            <ul>
                                <li>
                                    <a href="https://github.com/davidbanu" className="footer-company-items">Github  <i className="fa fa-external-link-alt"></i> </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/davidbanu_" className="footer-company-items">Instagram <i className="fa fa-external-link-alt"></i> </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/@davidbanu" className="footer-company-items">Medium <i className="fa fa-external-link-alt"></i> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <p className="footer-copyright-text text-center">Made with ❤️ by David Banu</p>
        </div>
    )
}

export default Footer;
