import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';



function February2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">February 2022</h1>

                <p className="title-grayed text-center"> Working, progress and the horror in Ukraine</p>

                <p className="text-now-page"> February was a good month for work. 
                I made good progress with my goals for 2022, both personally and work wise. 
                I've worked more than I intended, slept on average 5 hours per night,
                and had a weight loss of 4 kg's. I also continued writing for my book, Crushing Idols and read some great books.
                </p>
                <p className="text-now-page">
                At the end of the month, I felt helplessness and fear when I saw how Putin army started attacking Ukraine.
                How can I help this poor people in Ukraine, that didn't want a war, to the best of my abilities? Donating maybe, hacking Putin propaganda,
                renting spaces for those who run from their own country? This question, still remains in my mind. And I hope in the following weeks
                I will take the best decisions and help as many ukrainians as I can.</p>

                <p className="text-now-page"> In February my reading pile included: Man's search for meaning, Feeling is the secret, 
                The brain and emotional intelligence and Freakonomics.</p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> The most important thing that I want to work on is regarding sleep. I’ve slept for multiple days less than 4 hours. 
                I still sleep 4-5 hours for many nights in a row and every weekend I feel exhausted.
                I also want to continue the good work that I've already started with my weight loss. This year I want to reach my best
                shape so far, both physically and mentally. And weight loss will help a lot. And finally I want to work on my procrastination. Even thought I do a lot
                of things every day, I feel that I can have more results if I focus more and get shit done.</p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> Main focus will be the help of ukrainians in need, as best as I can. I also want to continue the weight loss process (my goal is 6 kg's this month),
                work to improve the <a className="now-link" href="https://joinstack.tech">joinstack</a> website, launching first version of an app for crypto tracking on Android and iOS, 
                continue investing in stock and crypto, write 4 articles on this website, finish work in 2 crypto projects, launching a npm package, and finally launching two free themes 
                (Lansera and SaaS Master) for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

            </div>

            <Footer />

        </div>

    )

}



export default February2022;
