import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function About() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">About Me</h1>
                <p className="text-center bio">I'm the founder of <a className="about-link" href="https://joinstack.tech"> Joinstack</a>,
                a digital startup focused on creating mobile and web apps for your daily needs. I've started Joinstack in 2018
                and since then we've made over 50 websites and web apps and developed 10 mobile apps for our clients all over the world.  
                Today I'm confident that we are one of the best software consultancy studios in Eastern Europe.</p>
                {/* <p className="text-center bio">I've also started <a className="about-link" href="https://uiroute.com">UiRoute</a>,
                a Marketplace with UI Kits, Templates and Dashboards specially made for developers and designers.</p>
                <p className="text-center bio">In my free time I write articles about life and coding and in 2023 I will launch my very first book, Worth it.</p>
                <p className="text-center bio">I’m always cooking up something new. I always have a bunch of side projects. So you should follow my blog to find out more.</p> */}
            </div>
            <Footer />
        </div>
    )
}

export default About;
