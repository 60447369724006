import React from 'react';


import TopNavBar from '../../components/TopNavBar';
import Footer from '../../components/Footer';

import mainImage from '../../images/april-picture.jpeg';

function April2022() {

    return (

        <div className="flex-wrapper">

            <div className="container">

                <TopNavBar />

                <h1 className="about-title text-center">April 2022</h1>

                <p className="title-grayed text-center"> Tough month, with small results...</p>

                <img src={mainImage} className="img-responsive center-block img-posts"></img>

                <p className="text-now-page"> April was really tough for me. I've got really sick and it took all the month to recover. 
                Maybe because of my hectic schedule that put a dent in my immune system or because of other causes. But either way, I felt very bad all month and this
                really affected my productivity. 
                </p>
                <p className="text-now-page">One thing that I'm doing right now, is trying to find ways to consolidate my position in the market for 
                Joinstack. And one way of doing this is to address the market for startups that want to develop their product very fast, with an MVP (Minimum Viable Product).
                Will tackle this, through a new product, <a className="now-link" href="https://mvpmonth.com">MVP Month</a>, which will be part of Joinstack. 
                A consultancy startup that will focus on providing an MVP in under a month for our clients. From wireframes to functional apps. 
                </p>

                <p className="text-now-page"> In April I was able to read only two books: Art of war and Self Discipline.</p>

                <h3 className="month-subtitle"> Things to work on in the future</h3>

                <p className="text-now-page"> First thing that I want to change for the future is to have an ideal weight. I still need to lose around 15kg.
                Finally, I want to work on my procrastination. Even though I do a lot
                of things every day, I feel that I can have more results if I focus more and get shit done.</p>

                <h3 className="month-subtitle"> What I want to do next month</h3>

                <p className="text-now-page"> I will focus mainly on the Joinstack projects that we currently have in progress. 
                I also want to continue the weight loss process (my goal is 4 kg's this month), create a website for <a className="now-link" href="https://mvpmonth.com">MVP Month</a>,
                launch a new product for developers, <a className="now-link" href="https://coderpaths.com">Coder Paths</a>, 
                launching the first version of an app for time control (DoFocus) 
                on Android and iOS, continue investing in stock and crypto, creating my first collection of NFT's, write 2 chapters for the book that I'm trying to launch, 
                finish some tasks for 4 crypto projects, launching an npm package, and finally launching two free themes 
                (Lansera and Agency) for <a className="now-link" href="https://uiroute.com">uiroute.com</a>.</p>

                <div className="row cards-stats-books">
                    <h3 className="text-center">Month stats</h3>
                     <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">2</p>
                        <p className="text-center text-numbers">Books</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">224</p>
                        <p className="text-center text-numbers">Worked hours</p>
                    </div>
                    <div className="col-md-4 col-xs-12 padding-top-5">
                        <p className="text-center numbers-subtitle">38</p>
                        <p className="text-center text-numbers">Meetings</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">1 kg</p>
                            <p className="text-center text-numbers">Weight loss</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">154</p>
                            <p className="text-center text-numbers">Commits</p>
                        </div>
                        <div className="col-md-4 col-xs-12 padding-top-5">
                            <p className="text-center numbers-subtitle">6.5 %</p>
                            <p className="text-center text-numbers">Investment return</p>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}



export default April2022;

