import React from 'react';

import {Link, Route} from 'react-router-dom';


function TopNavBar() {
    return (
        <nav>
            <input type="checkbox" id="nav" className="hidden" />
            <label htmlFor="nav" className="nav-btn">
                <i />
                <i />
                <i />
            </label>
            <div className="logo">
                <Link to="/">David Banu</Link>
            </div>
            <div className="nav-wrapper">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About Me</Link>
                    </li>
                    <li>
                        <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                        <Link to="/now">Now</Link>
                    </li>
                    <li>
                        <Link to="/month-notes">Month Notes</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default TopNavBar;