import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './screens/Home';
import About from './screens/About';
import Blog from './screens/Blog';
import Now from './screens/Now';
import BookNotes from './screens/BookNotes';
import MonthNotes from './screens/MonthNotes';

import ScrollToTop from './components/ScrollToTop';
import BooksRead from './screens/BooksRead';
import January2022 from './screens/month-notes-entries/January2022';
import February2022 from './screens/month-notes-entries/February2022';
import March2022 from './screens/month-notes-entries/March2022';
import April2022 from './screens/month-notes-entries/April2022';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} />
        <Route path="/now" component={Now} />
        <Route path="/books-read" component={BooksRead} />
        <Route exact path="/month-notes" component={MonthNotes} />
        <Route path="/month-notes/january-2022" component={January2022} />
        <Route path="/month-notes/february-2022" component={February2022} />
        <Route path="/month-notes/march-2022" component={March2022} />
        <Route path="/month-notes/april-2022" component={April2022} />
      </Switch>
    </Router>
  );
}

export default App;
