import React from 'react';

import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function MonthNotes() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="about-title text-center">Month Notes</h1>
                <p className="text-now-page text-center">On this page I keep a collection of my month-to-month notes.</p>
                {/* <p className="text-now-page text-center"><a className="now-link" href="/month-notes/april-2022">April 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/march-2022">March 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/february-2022">February 2022</a></p>
                <p className="text-now-page text-center"><a className="now-link" href="/month-notes/january-2022">January 2022</a></p> */}
            </div>
            <Footer />
        </div>
    )
}

export default MonthNotes;
